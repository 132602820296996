.sd-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  margin-top: var(--sd-base-vertical-padding);
}

.sd-row.sd-page__row {
  margin-top: calcSize(2);
}

.sd-page__row.sd-row--compact {
  margin-top: var(--sd-base-vertical-padding);
}

.sd-row:first-of-type {
  margin-top: 0;
}

.sd-page__title,
.sd-page__description {
  & ~ .sd-row.sd-page__row:not(.sd-row--compact) {
    margin-top: calcSize(3);
  }

  & ~ .sd-page__row.sd-row--compact {
    margin-top: var(--sd-base-vertical-padding);
  }
}

.sd-row.sd-page__row:not(.sd-row--compact) {
  & ~ .sd-row.sd-page__row:not(.sd-row--compact) {
    margin-top: calcSize(2);
  }
}

.sd-row--multiple {
  row-gap: calcSize(2);
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + var(--sd-base-padding));
  flex-wrap: wrap;

  & > div {
    box-sizing: border-box;
    padding-left: calcSize(2);
    display: flex;
    align-items: stretch;
  }
}

.sd-panel:not(.sd-panel--as-page) {
  .sd-row--multiple {
    row-gap: var(--sd-base-vertical-padding);

    & > div {
      padding-left: var(--sd-base-padding);
    }
  }
}

.sd-row--multiple.sd-row--compact {
  & > div {
    padding-left: var(--sd-base-padding);
  }
}

.sd-page__row.sd-row--multiple {
  margin-left: calcSize(-2);
  width: calc(100% + 2 * var(--sjs-base-unit, var(--base-unit, 8px)));

  &.sd-row--compact {
    padding: 0;
    row-gap: var(--sd-base-vertical-padding);
    margin-left: calc(-1 * var(--sd-base-padding));
    width: calc(100% + var(--sd-base-padding));
  }
}

.sd-row__panel {
  box-sizing: border-box;
  width: 100%;
}

.sd-row__question {
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
}
