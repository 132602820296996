@import "../defaultV2-theme/variables.scss";

.sv-action-bar {
  display: flex;
  box-sizing: content-box;
  position: relative;
  align-items: center;
  margin-left: auto;
  overflow: hidden;
  white-space: nowrap;
}

.sv-action-bar-separator {
  display: inline-block;
  width: 1px;
  height: 24px;
  vertical-align: middle;
  margin-right: 16px;
  background-color: $border;
}

.sv-action-bar--default-size-mode .sv-action-bar-separator {
  margin: 0 calcSize(1);
}

.sv-action-bar--small-size-mode .sv-action-bar-separator {
  margin: 0 calcSize(0.5);
}

.sv-action-bar-item {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  padding: calcSize(1);
  box-sizing: border-box;
  border: none;
  border-radius: calcCornerRadius(0.5);
  background-color: transparent;
  color: $foreground;
  cursor: pointer;

  font-family: $font-family;
  overflow-x: hidden;
  white-space: nowrap;
}

button.sv-action-bar-item {
  overflow: hidden;
}

.sv-action-bar--default-size-mode .sv-action-bar-item {
  height: calcSize(5);
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);
  margin: 0 calcSize(1);
}

.sv-action-bar--small-size-mode .sv-action-bar-item {
  height: calcSize(4);
  font-size: calcFontSize(0.75);
  line-height: calcLineHeight(1);
  margin: 0 calcSize(0.5);
}

.sv-action:first-of-type .sv-action-bar-item {
  margin-inline-start: 0; //margin-left
}

.sv-action:last-of-type .sv-action-bar-item {
  margin-inline-end: 0; //margin-right
}

.sv-action-bar--default-size-mode .sv-action-bar-item__title--with-icon {
  margin-inline-start: calcSize(1);
}

.sv-action-bar--small-size-mode .sv-action-bar-item__title--with-icon {
  margin-inline-start: calcSize(0.5);
}

.sv-action-bar-item__icon {
  svg {
    display: block;
  }

  use {
    fill: $foreground-light;
  }
}

//hovered state
.sv-action-bar-item:not(.sv-action-bar-item--pressed):hover:enabled,
.sv-action-bar-item:not(.sv-action-bar-item--pressed):focus:enabled {
  outline: none;
  background-color: $background-dim;
}

.sv-action-bar-item--active.sv-action-bar-item--pressed {

  &:focus,
  &:focus-visible {
    outline: none;
    // background-color: $background-dim;
  }
}

//pressed state
.sv-action-bar-item:not(.sv-action-bar-item--pressed):active:enabled {
  opacity: 0.5;
}

//disabled state
.sv-action-bar-item:disabled {
  opacity: 0.25;
  cursor: default;
}

.sv-action-bar-item__title {
  color: inherit;
  vertical-align: middle;
  white-space: nowrap;
}

.sv-action-bar-item--secondary {
  .sv-action-bar-item__icon use {
    fill: $secondary;
  }
}

.sv-action-bar-item--active {
  .sv-action-bar-item__icon use {
    fill: $primary;
  }
}

.sv-action-bar-item-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  height: calcSize(5);
  padding: calcSize(1);
  box-sizing: border-box;
  border: none;
  border-radius: calcCornerRadius(0.5);
  background-color: transparent;
  cursor: pointer;
  line-height: calcLineHeight(1.5);
  font-size: calcFontSize(1);
  font-family: $font-family;
}

.sv-expand-action {
  &:before {
    content: "";
    display: inline-block;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center center;
    height: 10px;
    width: 12px;
    margin: auto 8px;
  }
}

.sv-expand-action--expanded {
  &:before {
    transform: rotate(180deg);
  }
}

.sv-dots {
  width: 48px;
}

.sv-dots__item {
  width: 100%;

  .sv-action-bar-item__icon {
    margin: auto;
  }
}

.sv-action--hidden {
  width: 0px;
  height: 0px;
  overflow: hidden;
  visibility: hidden;
  .sv-action__content {
    min-width: fit-content;
  }
}

.sv-action__content {
  display: flex;
  flex-direction: row;
  align-items: center;

  &>* {
    flex: 0 0 auto;
  }
}

.sv-action--space {
  margin-left: auto;
}

.sv-action-bar-item--pressed:not(.sv-action-bar-item--active) {
  background-color: $background-dim;
  opacity: 50%;
}