.sd-error {
  display: block;
  padding: calcSize(1) calcSize(1.5);
  border-radius: calcCornerRadius(1);
  line-height: calcLineHeight(1);
  font-size: calcFontSize(0.75);
  font-weight: 600;
  text-align: left;
  color: $red;
  white-space: normal;
  width: 100%;
  background-color: $red-light;
  box-sizing: border-box;
}