.sd-page {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 calcSize(5);
  width: 100%;
  box-sizing: border-box;
}

.sd-root-modern--mobile .sd-page {
  padding: 0;
}

.sd-page .sd-page__title {
  @include page_title();
}

.sd-page .sd-page__description {
  @include page_description();
}


.sd-page__errbox {
  padding: calc(0.5 * var(--sd-base-vertical-padding) + #{$base-unit}) var(--sd-base-padding);
}

.sd-page__title,
.sd-page__description {
  &~.sd-page__errbox {
    margin-top: calc(0.5 * var(--sd-base-vertical-padding) + #{$base-unit});
    margin-bottom: calcSize(-1);
  }
}

.sd-root--compact {

  .sd-page__title,
  .sd-page__description {
    &~.sd-page__errbox {
      margin-bottom: 0;
    }
  }
}