@mixin page_title {
  --page-title-font-size: var(--sjs-font-pagetitle-size, calc(1.5 * (var(--sjs-font-size, 16px))));
  font-family: $font-pagetitle-family;
  font-weight: $font-pagetitle-weight;
  font-size: var(--page-title-font-size);
  color: $font-pagetitle-color;
  position: static;
  line-height: multiply(1.33, var(--page-title-font-size));
  margin: 0 0 calcSize(0.5) 0px;
}

@mixin survey_title {
  --survey-title-font-size: var(--sjs-font-surveytitle-size, calc(2 * (var(--sjs-font-size, 16px))));
  font-size: var(--survey-title-font-size);
  line-height: multiply(1.25, var(--survey-title-font-size));
}

@mixin survey_description {
  --survey-description-font-size: var(--sjs-font-surveydescription-size, var(--sjs-font-size, 16px));
  font-size: var(--survey-description-font-size);
  line-height: multiply(1.5, var(--survey-description-font-size));
}

@mixin header_title {
  --header-title-font-size: var(--sjs-font-headertitle-size, calc(2 * (var(--sjs-font-size, 16px))));
  font-size: var(--header-title-font-size);
  line-height: multiply(1.25, var(--header-title-font-size));
}

@mixin header_description {
  --header-description-font-size: var(--sjs-font-headerdescription-size, var(--sjs-font-size, 16px));
  font-size: var(--header-description-font-size);
  line-height: multiply(1.5, var(--header-description-font-size));
}

@mixin page_description {
  font-family: $font-pagedescription-family;
  font-weight: $font-pagedescription-weight;
  font-size: $font-pagedescription-size;
  color: $font-pagedescription-color;
  position: static;
  line-height: multiply(1.5, $font-pagedescription-size);
  margin: 0 0 calcSize(0.5) 0;
}

@mixin num_inline {
  float: none;
  margin-inline-start: 0;
  width: auto;
  padding-inline-start: 0;
  padding-inline-end: 0;

  & + span {
    float: none;
    width: auto;
  }
}

@mixin scrollRules {
  &,
  * {
    @-moz-document url-prefix() {
      scrollbar-width: thin;
      scrollbar-color: $border transparent;
    }

    &::-webkit-scrollbar {
      width: 12px;
      height: 12px;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 4px solid rgba(0, 0, 0, 0);
      background-clip: padding-box;
      border-radius: 32px;
      background-color: $border;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      border: 2px solid rgba(0, 0, 0, 0);
      background-color: $foreground-light;
    }
  }
}