.sd-panel {
  position: relative;
}

.sd-panel.sd-panel--as-page {
  &>.sd-panel__header.sd-panel__header {
    padding-top: 0;
    padding-bottom: calc(0.5 * var(--sd-base-vertical-padding) + #{$base-unit});

    &:after {
      content: none;
    }

    .sd-panel__title {
      @include page_title();

      span {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
        line-height: inherit;
      }
    }

    .sd-panel__description {
      @include page_description();
    }

    .sd-panel__required-text {
      display: none;
    }
  }

  &>.sd-panel__errbox {
    margin: 0 0 calcSize(2) 0;
    padding: calc(0.5 * var(--sd-base-vertical-padding) + #{$base-unit}) var(--sd-base-padding);
  }

  &>.sd-panel__content {
    padding-top: 0;
  }
}

.sd-root--compact {
  .sd-panel--as-page {
    &>.sd-panel__errbox {
      margin: 0 0 var(--sd-base-vertical-padding) 0;
    }
  }
}

.sd-row~.sd-row {
  .sd-panel--as-page {
    padding-top: calcSize(4);
  }
}

.sd-panel__required-text {
  color: $red;
}

.sd-panel__footer {
  box-sizing: border-box;
  padding-left: calc(var(--sd-base-padding) - 3 * #{$base-unit});
  margin-left: calc(-1 * var(--sd-base-padding));
  width: calc(100% + 2 * var(--sd-base-padding));
  margin-bottom: calc(-1 * var(--sd-base-padding) + 0.5 * var(--sd-base-vertical-padding));
  margin-top: calc(0.5 * var(--sd-base-vertical-padding));
  border-top: 1px solid $border-light;
  padding-top: calc(0.5 * var(--sd-base-vertical-padding));
}

.sd-panel__content {
  padding-top: var(--sd-base-padding);
}

.sd-panel.sd-element--nested {
  &>.sd-panel__content {
    padding-bottom: var(--sd-base-padding);
  }
}