.sd-matrixdynamic__btn.sd-matrixdynamic__add-btn {
  position: sticky;
  left: calcSize(-3);
  margin-left: calcSize(-3);
  z-index: 12;
}

.sd-matrixdynamic__footer:first-child {
  padding-bottom: calcSize(2);
}

.sd-matrixdynamic__footer {
  padding-top: calcSize(2);
}

.sd-action.sd-matrixdynamic__remove-btn {
  opacity: 0.5;
}

.sd-action.sd-matrixdynamic__remove-btn .sd-action__icon {
  @include useEditorFontSize;
  width: multiply(1.5, $font-editorfont-size);
  height: multiply(1.5, $font-editorfont-size);
}

.sd-matrixdynamic__btn {
  appearance: none;
  background: transparent;
  border: none;
  line-height: calcLineHeight(1.5);
  font-size: calcFontSize(1);
  font-family: $font-family;
  font-weight: 600;
  padding: calcSize(1) 0;
}

.sd-matrixdynamic__drag-element {
  padding: calcSize(2);

  &:hover {
    background-color: $background;
  }

  &:after {
    content: " ";
    display: block;
    height: calcSize(0.5);
    width: calcSize(2);
    border: 1px solid $border;
    box-sizing: border-box;
    border-radius: calcSize(1.25);
  }
}

.sd-matrixdynamic__placeholder {
  .sd-matrixdynamic__add-btn {
    margin-left: 0;
  }
}

.sd-drag-element__svg {
  width: calcSize(2);
  height: calcSize(2);
  display: block;

  use {
    fill: $foreground-light;
  }
}