.sd-checkbox__decorator {
  border-radius: calcCornerRadius(0.5);
}
.sd-checkbox__svg {
  display: block;
  width: calcSize(2.5);
  height: calcSize(2.5);

  use {
    fill: transparent;
    transition: fill $transition-duration;
  }
}

.sd-checkbox--checked .sd-checkbox__svg {
  use {
    fill: $primary-foreground;
  }
}

.sd-checkbox--checked.sd-checkbox--disabled .sd-checkbox__svg use {
  fill: $border;
}
.sd-checkbox--checked .sd-checkbox__control:focus + .sd-checkbox__decorator .sd-checkbox__svg use {
  fill: $primary;
}
